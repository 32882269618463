@font-face {
  font-family: "MyFontRegular";
  src: local("MyFontRegular"), url(./fontfamily/PTSansNarrow-Regular.ttf);
}

@font-face {
  font-family: "MyFontBold";
  src: local("MyFontBold"), url(./fontfamily/PTSansNarrow-Bold.ttf);
}

body {
  font-family: MyFontRegular !important;
  margin: 0px !important;
}
